var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"l-padded u-bb"},[_c('SearchField',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('ListHeader',{staticClass:"u-bb"},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t('listedOf', [ _vm.filteredAssetTypes.length, _vm.assetTypes.length ]))+" ")])]),_c('List',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.increaseScrollLimit),expression:"increaseScrollLimit"}],attrs:{"infinite-scroll-distance":"15"}},_vm._l((_vm.assetTypesPortion),function(assetType){return _c('router-link',{key:assetType.id,attrs:{"to":{
                name: 'allAssets',
                query: { type: assetType.id },
                params: { backRouteName: 'assetTypes' },
            }}},[_c('ListItem',[_c('div',{staticClass:"l-inline l-gap-2 l-center-v"},[_c('AssetAvatar',{attrs:{"tracker":{
                            asset_details: {
                                asset_type_marker: assetType.marker.url,
                            },
                        },"size":42}}),_c('h3',[_vm._v(_vm._s(assetType.name))])],1),_c('span',[_vm._v(_vm._s(_vm.assetsByType()[assetType.identifier] || 0))])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }